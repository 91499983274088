import styled from "styled-components";
import { checkSlidesPerView } from "../../../helpers/helpers";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { IBoxProductSliderDisplaySettings } from "../BoxProductSlider.types";

interface IStyledProductSliderGrid {
  numberOfProductsPerRow: number;
  displaySettings: IBoxProductSliderDisplaySettings;
}

export const StyledProductSliderGrid = styled.div<IStyledProductSliderGrid>(
  ({ numberOfProductsPerRow, displaySettings }) => {
    const isMobile = useIsMobilePortal();
    return `
    display: grid;
    grid-template-columns: repeat(${checkSlidesPerView(
      numberOfProductsPerRow,
      isMobile ?? false
    )}, 1fr);
    word-break: break-all;
    gap: 
        ${displaySettings.pageStyle.spaceBetweenRows}px 
        ${displaySettings.pageStyle.spaceBetweenProducts}px;
  `;
  }
);
