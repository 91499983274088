import { useEffect } from "react";
import { SliderPaginationOptions } from "../../../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import PaginationGrid from "../PaginationGrid/PaginationGrid";
import { PaginationGridWrapper } from "../PaginationGrid/PaginationGrid.styled";
import { IProductSliderPaginationGridProps } from "./ProductSliderPaginationGrid.types";

const ProductSliderPaginationGrid: React.FC<IProductSliderPaginationGridProps> =
  ({
    currentPage,
    setCurrentPage,
    pageCount,
    paginationStyle,
    rotatePages,
    editMode,
    pauseInterval,
  }) => {
    useEffect(() => {
      let intervalId: NodeJS.Timeout;
      if (
        rotatePages &&
        rotatePages[0] !== 0 &&
        editMode === false &&
        !pauseInterval
      ) {
        intervalId = setInterval(() => {
          if (currentPage < pageCount - 1) {
            setCurrentPage((prevCurrentPage: number) => prevCurrentPage + 1);
          } else {
            setCurrentPage(0);
          }
        }, rotatePages[0] * 1000);
      }

      return () => clearInterval(intervalId);
    }, [
      rotatePages,
      editMode,
      currentPage,
      pageCount,
      setCurrentPage,
      pauseInterval,
    ]);

    if (paginationStyle === SliderPaginationOptions.NONE) return null;

    return (
      <PaginationGridWrapper
        className="pagination-container"
        data-testid="pagination-container"
      >
        <PaginationGrid
          currentPage={currentPage}
          pageCount={pageCount}
          paginationStyle={paginationStyle}
          setCurrentPage={setCurrentPage}
        />
      </PaginationGridWrapper>
    );
  };

export default ProductSliderPaginationGrid;
