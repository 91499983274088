export const getElementClassName = (
  elementIndex: number,
  currentPage: number
): string => {
  const position = elementIndex - currentPage;

  switch (position) {
    case -2:
      return "prev-prev";
    case -1:
      return "prev";
    case 0:
      return "current";
    case 1:
      return "next";
    case 2:
      return "next-next";
    default:
      return "";
  }
};
