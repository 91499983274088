import { useState } from "react";
import { PageCategoryType } from "@ecp-pageTypes";
import {
  ISrpTransformResponseV2,
  SortingItemQuery,
  SortingItemType,
  SortingItemVariant,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import {
  ProductContext,
  useCheckPageContext,
} from "../../../helpers/pageContext";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import {
  IBoxProductSliderContentSettings,
  dynamicProductListOption,
} from "../BoxProductSlider.types";
import { productList } from "@ecp-redux/api/productList";

export const getSortQueryBySortType = (
  sort: SortingItemType,
  sortingAttributeCode: string,
  sortingAttributeVariant: string
): string => {
  switch (sort) {
    case SortingItemType.NAME_ASC: {
      return SortingItemQuery.NAME_ASC;
    }
    case SortingItemType.NAME_DESC: {
      return SortingItemQuery.NAME_DESC;
    }
    case SortingItemType.POPULARITY: {
      return SortingItemQuery.POPULARITY;
    }
    case SortingItemType.PRICE_ASC: {
      return SortingItemQuery.PRICE_ASC;
    }
    case SortingItemType.PRICE_DESC: {
      return SortingItemQuery.PRICE_DESC;
    }
    case SortingItemType.RATING_ASC: {
      return SortingItemQuery.RATING_ASC;
    }
    case SortingItemType.RATING_DESC: {
      return SortingItemQuery.RATING_DESC;
    }
    case SortingItemType.RANDOM: {
      return SortingItemQuery.RANDOM;
    }
    case SortingItemType.DATE_ADDED: {
      return SortingItemQuery.DATE_ADDED;
    }
    case SortingItemType.DATE_ADDED_DESC: {
      return SortingItemQuery.DATE_ADDED_DESC;
    }
    case SortingItemType.BY_ATTRIBUTE: {
      if (sortingAttributeVariant === SortingItemVariant.DESC) {
        return `-${sortingAttributeCode}`;
      } else {
        return sortingAttributeCode;
      }
    }
    default:
      return SortingItemQuery.DATE_ADDED_DESC;
  }
};

const useBoxProductSliderData = (
  contentSettings: IBoxProductSliderContentSettings
) => {
  const { dynamicProductListOption: dynamicListOption } = contentSettings;

  const [currentPage, setCurrentPage] = useState(0);

  const isMobile = useIsMobilePortal();

  const context = useCheckPageContext<ProductContext>(PageCategoryType.PRODUCT);

  const productSku =
    context.type === PageCategoryType.PRODUCT ? context.productSku : null;

  const productsForSlider = productList.useGetProductsForSliderQuery({
    contentSettings,
    productSku: productSku,
  });

  const wishListMap: Record<string, TSrpProductType> | undefined =
    productsForSlider.data?.wishListData?.data?.products?.reduce(
      (acc: any, product: any) => ({ ...acc, [product.sku]: product.type }),
      {}
    );

  const products: ISrpTransformResponseV2[] =
    (dynamicListOption &&
      dynamicListOption !== dynamicProductListOption.WISH_LIST) ||
    wishListMap === undefined
      ? (productsForSlider.data?.productsData?.products ?? [])
      : (productsForSlider.data?.productsData?.products.map((product: any) => ({
          ...product,
          type: wishListMap[product.conceptId ?? product.sku],
        })) ?? []);

  const numberOfProductsPerRow = isMobile
    ? Math.min(contentSettings.sliderSettings.numberOfProductsPerRow, 2)
    : contentSettings.sliderSettings.numberOfProductsPerRow;

  const productsPerPage = Math.min(
    numberOfProductsPerRow * contentSettings.sliderSettings.numberOfRows,
    contentSettings.sliderSettings.maxNumberOfProductsGrid
  );

  return {
    productsData: {
      products: products,
      size: productsForSlider.data?.productsData?.size ?? 0,
      attributesLineDetection:
        productsForSlider.data?.productsData?.attributesLineDetection,
    },
    context,
    currentPage,
    setCurrentPage,
    productsPerPage,
  };
};

export default useBoxProductSliderData;
