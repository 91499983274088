import { useEffect, useMemo, useState } from "react";

const useCountSlidesPerView = (
  maxNumberOfProducts: number,
  numberOfProductsPerSlide: number,
  productsLength: number
) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(productsLength);
  }, [productsLength]);

  const countFromSettings = useMemo(() => {
    return numberOfProductsPerSlide >= maxNumberOfProducts
      ? maxNumberOfProducts
      : numberOfProductsPerSlide;
  }, [maxNumberOfProducts, numberOfProductsPerSlide]);

  const finalCount = useMemo(() => {
    return count && countFromSettings > count ? count : countFromSettings;
  }, [count, countFromSettings]);

  return finalCount;
};

export default useCountSlidesPerView;
