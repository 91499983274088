import { SliderPaginationOptions } from "./StyledSlider.types";

export const counterBullet = (index: number, className: string) => {
  return `<span class="pagination-counter ${className}">${index + 1}</span>`;
};

export const getPaginationStyle = (
  paginationStyle: SliderPaginationOptions
) => {
  if (paginationStyle === SliderPaginationOptions.NUMBERS) {
    return {
      pagination: {
        dynamicBullets: true,
        clickable: true,
        renderBullet: counterBullet,
      },
    };
  } else if (paginationStyle === SliderPaginationOptions.DOTS) {
    return {
      pagination: {
        dynamicBullets: true,
        clickable: true,
        renderBullet: () => '<span class="swiper-pagination-bullet"></span>',
      },
      navigation: false,
    };
  } else if (paginationStyle === SliderPaginationOptions.ARROWS) {
    return {
      navigation: true,
      pagination: false,
    };
  } else {
    return { navigation: false, pagination: false };
  }
};

export const isAutoplaying = (slidesRotate?: number[]) => {
  if (!Array.isArray(slidesRotate) || slidesRotate[0] === 0) return;

  return {
    delay: slidesRotate[0] * 1000,
  };
};
