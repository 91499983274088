import styled from "styled-components";
import { SliderPaginationOptions } from "../../../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";

export const StyledPaginationButton = styled.div<{
  paginationStyle: Omit<
    SliderPaginationOptions,
    SliderPaginationOptions.ARROWS
  >;
}>(
  ({ paginationStyle }) => `
    ${
      paginationStyle === SliderPaginationOptions.DOTS &&
      `
        .current {
          scale: 1.8;
          color: #CCCCCC;
        }
        
        .prev, .next {
          scale: 1.3;
          color: #4578B7;
        }
      
        .prev-prev, .next-next {
          color: #4578B7;
        }`
    } 

    ${
      paginationStyle === SliderPaginationOptions.NUMBERS &&
      `
        .current {
          color: #293740;
        }
        
        .prev-prev, .prev, .next, .next-next {
          color: #81889C;
        }`
    }
  `
);

export const PaginationGridWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;
