import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { PageCategoryType } from "@ecp-pageTypes";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import React, { useEffect, useMemo, useState } from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import { useTheme } from "styled-components";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Swiper as SwiperProps,
} from "swiper";
import { SwiperSlide } from "swiper/react";
import { checkSlidesPerView, isPortalSide } from "../../helpers/helpers";
import { getPaginationStyle } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.methods";
import { SliderPaginationOptions } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import { StyledSwiper } from "../../settingsPatterns/elements/StyledSwiper/StyledSwiper";
import { getPreparedSettings } from "../../settingsPatterns/settingsPatterns.methods";
import ProductModule from "../../shared/components/domain/Product/ProductModule/ProductModule";
import useCountSlidesPerView from "../../shared/hooks/useCountSlidesPerView";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import {
  BoxProductSliderContentSchema,
  BoxProductSliderContentSettingsSchema,
  BoxProductSliderDisplaySettingsScheme,
  getDefaultProductCustomAttributeSettings,
} from "./BoxProductSlider.scheme";
import {
  IBoxProductSliderProps,
  SlideRotateOptions,
  dynamicProductListOption,
} from "./BoxProductSlider.types";
import { StyledProductSlider } from "./StyledProductSlider.styled";
import useBoxProductSliderData from "./hooks/useBoxProductSliderData";
import BoxProductSliderGrid from "./views/BoxProductSliderGrid";

import { EVENT_ORIGIN } from "../analytics/const";
import { useListingTrackerEvent } from "../analytics/hooks/useListingTrackerEvent";

const BoxProductSlider: React.FC<IBoxProductSliderProps> = (
  props: IBoxProductSliderProps
) => {
  const [swiper, setSwiper] = useState<SwiperProps | null>(null);
  const themeSettings = useTheme() as IThemeState;

  const displaySettings = BoxProductSliderDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const tmpCont = BoxProductSliderContentSettingsSchema.parse(
    props.contentSettings
  );

  const contentSettings = {
    ...tmpCont,
    product: {
      ...tmpCont.product,
      custom: Object.fromEntries(
        Object.entries(tmpCont.product.custom).map(([key, value]) => [
          key,
          getPreparedSettings(
            value,

            getDefaultProductCustomAttributeSettings()
          ),
        ])
      ),
    },
  };

  const content = BoxProductSliderContentSchema.parse(props.content);

  const {
    productsData,
    context: { type: ctxType },
  } = useBoxProductSliderData(contentSettings);

  const { dynamicProductListOption: dynamicOption } = contentSettings;

  const numberProductPerSlide = useMemo(
    () =>
      Math.min(
        contentSettings.sliderSettings?.numberOfProductsPerSlide,
        productsData?.size
      ),
    [
      contentSettings.sliderSettings?.numberOfProductsPerSlide,
      productsData?.size,
    ]
  );

  useEffect(() => {
    if (swiper !== null && swiper !== undefined) {
      swiper.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberProductPerSlide]);

  useEffect(() => {
    if (swiper !== null && swiper !== undefined) {
      swiper.pagination?.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySettings.paginationStyle]);

  useListingTrackerEvent({
    origin: EVENT_ORIGIN.BOX_PRODUCT_SLIDER,
    deps: [productsData?.products?.length],
    products: productsData?.products,
  });

  const isMobile = useIsMobilePortal();

  const count = useCountSlidesPerView(
    numberProductPerSlide,
    contentSettings.sliderSettings.maxNumberOfProducts,
    productsData.products.length
  );

  if (
    dynamicOption === dynamicProductListOption.WISH_LIST &&
    !productsData.size
  ) {
    return (
      <StyledProductSlider
        className="product-slider product-slider-custom-style"
        padding={contentSettings.header.padding}
        alignment={contentSettings.header.contentAlignment}
      >
        {contentSettings.header.show && (
          <div className="product-slider__heading">
            <StyledText
              $settings={contentSettings.header}
              className="product-slider__heading__text"
            >
              {content.headerText}
            </StyledText>
          </div>
        )}
        <div>{props.messages.no_content_message}</div>
      </StyledProductSlider>
    );
  }

  if (
    !isPortalSide() &&
    dynamicOption === dynamicProductListOption.PRODUCT_ASSOCIATION &&
    (ctxType !== PageCategoryType.PRODUCT ||
      (ctxType === PageCategoryType.PRODUCT && !productsData.size))
  ) {
    return <div>{props.messages.no_content_message}</div>;
  }

  if (
    !isPortalSide() &&
    dynamicOption === dynamicProductListOption.DYNAMIC_ASSOCIATION &&
    (ctxType !== PageCategoryType.PRODUCT ||
      (ctxType === PageCategoryType.PRODUCT && !productsData.size))
  ) {
    return (
      <div>
        {contentSettings.header.show && (
          <div className="product-slider__heading">
            <StyledText
              $settings={contentSettings.header}
              className="product-slider__heading__text"
            >
              {content.headerText}
            </StyledText>
          </div>
        )}
        {props.messages.no_content_message}
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {productsData.products.length > 0 && (
        <StyledProductSlider
          key={count}
          data-testid="product-slider"
          className="product-slider product-slider-custom-style"
          padding={contentSettings.header.padding}
          alignment={contentSettings.header.contentAlignment}
        >
          {contentSettings.header.show && (
            <div className="product-slider__heading">
              <StyledText
                $settings={contentSettings.header}
                className="product-slider__heading__text"
              >
                {content.headerText}
              </StyledText>
            </div>
          )}
          {contentSettings.sliderSettings.type === "SLIDER" && (
            <StyledSwiper
              className="product-slider__swiper"
              observer={true}
              loop={true}
              modules={[Navigation, A11y, Autoplay, Pagination]}
              slidesPerView={checkSlidesPerView(count, isMobile ?? false)}
              slidesPerGroup={
                displaySettings.slideRotateType === SlideRotateOptions.SINGLE &&
                displaySettings.paginationStyle ===
                  SliderPaginationOptions.ARROWS
                  ? 1
                  : numberProductPerSlide
              }
              onSwiper={setSwiper}
              autoplay={
                props.editMode === false &&
                displaySettings.slidesRotate[0] !== 0
                  ? (() => {
                      swiper?.autoplay && swiper?.autoplay.start();
                      return {
                        delay: displaySettings.slidesRotate[0] * 1000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      };
                    })()
                  : swiper?.autoplay && swiper?.autoplay.stop()
              }
              $settings={{
                elementsCount: numberProductPerSlide,
                theme: themeSettings,
                paginationStyle: displaySettings.paginationStyle,
              }}
              {...(displaySettings.paginationStyle === "ARROWS" &&
              numberProductPerSlide >=
                contentSettings.sliderSettings.maxNumberOfProducts
                ? {}
                : {
                    ...getPaginationStyle(displaySettings.paginationStyle),
                  })}
              spaceBetween={displaySettings.module.spaceBetweenSlides}
              key={numberProductPerSlide}
              shortSwipes={false}
              watchSlidesProgress
            >
              <div className="slider-container product-slider__slides-container">
                {productsData.products.map((product) => {
                  return (
                    <SwiperSlide
                      data-testid="slider-slide"
                      key={product.sku + productsData.products.length}
                      className="product-slider__slides-container__single-slide"
                    >
                      <ProductModule
                        product={product}
                        moduleSettings={displaySettings.module}
                        productPhoto={contentSettings.productPhoto}
                        badge={contentSettings.badge}
                        favorites={contentSettings.favorites}
                        buttonText={props.content.productButtonText}
                        ratingText={props.content.ratingText}
                        productSettings={contentSettings.product}
                        orderInModule={displaySettings.orderInModule}
                        key={product.sku}
                        boxId={props.id}
                        boxType={props.type}
                        variantSettings={contentSettings.variants?.find(
                          (variant) => variant.code === product.axis
                        )}
                        attributesLineDetection={
                          productsData.attributesLineDetection
                        }
                      />
                    </SwiperSlide>
                  );
                })}
              </div>
            </StyledSwiper>
          )}
          {contentSettings.sliderSettings.type === "GRID" && (
            <BoxProductSliderGrid
              contentSettings={contentSettings}
              displaySettings={displaySettings}
              content={content}
              id={props.id}
              type={props.type}
              editMode={props.editMode}
            />
          )}
        </StyledProductSlider>
      )}
    </>
  );
};

export default Memo(
  withHydrationOnDemand({ on: ["visible"] })(BoxProductSlider)
);
