import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ProductButtonActions } from "@ecp-boxes/shared/components/domain/Product/ProductModule/ProductModule.types";
import * as searchResults from "../api/searchResults";
import { IPaginationGridPayload } from "../dto/paginationResponse.types";
import {
  IProductAttributePm,
  IProductListAttributesResponse,
  IProductListAttributesTypeResponse,
  IProductListCategoryTreeResponse,
  IProductListPoolsResponse,
  IProductPageConfigResponse,
  IProductProjectionResponse,
  IProductsResponse,
  TAttribute,
  TCategory,
  TCategoryResponse,
} from "../dto/productList.types";
import {
  ISearchResultsTransformResponse,
  ISrpTransformResponseV2,
} from "../dto/searchResults.types";
import { API, api } from "./api";
import { QueryReturnValue } from "./pages";
import {
  getProductsDataObject,
  getDynamicAssociationSkus,
  getSearchRequestConfig,
} from "@ecp-boxes/boxes/BoxProductSlider/BoxProductSlider.methods";
import {
  IBoxProductSliderData,
  dynamicProductListOption,
} from "@ecp-boxes/boxes/BoxProductSlider/BoxProductSlider.types";
import { getSortQueryBySortType } from "@ecp-boxes/boxes/BoxProductSlider/hooks/useBoxProductSliderData";
import { srpProjectionMapper } from "./searchResults.methods";
import { getLineAttributesDetectedV2 } from "@ecp-redux/helpers";

export const productList = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<
      IProductAttributePm[],
      { onlyFilterable: boolean; extra: IProductAttributePm[] }
    >({
      query: ({ onlyFilterable }) => {
        return onlyFilterable
          ? API.getFilterableSearchAttributes.endpoint()
          : API.getSearchAttributes.endpoint();
      },
      transformResponse: (
        resp: {
          attributes: TAttribute[];
        },
        _,
        { extra }
      ) =>
        [
          ...resp.attributes.map((attrib) => ({
            attributeId: attrib.id,
            label: attrib.label,
            type: attrib.type,
            numberMin: attrib.numberMin,
            numberMax: attrib.numberMax,
            filterable: attrib.filterable,
            options: attrib.options,
          })),
          ...extra,
        ].sort((a, b) =>
          (a.label ?? a.attributeId).localeCompare(b.label ?? b.attributeId)
        ),
    }),
    getProducts: builder.query<IProductsResponse, IPaginationGridPayload>({
      queryFn: async (body, { getState }, _1, baseQuery) => {
        if ((getState() as any).dragAndDrop.layout.rootId === null) {
          const productPageResponse: QueryReturnValue<
            IProductPageConfigResponse,
            FetchBaseQueryError
          > = await baseQuery(API.getProductPageConfigs.endpoint());

          const categoryIds = productPageResponse.data?.categoryIds ?? [];
          const skus = productPageResponse.data?.skus ?? [];

          return baseQuery({
            url: API.getProducts.endpoint(),
            method: "POST",
            body: {
              ...body,
              filter: [
                ...body.filter,
                ...(categoryIds.length > 0
                  ? [
                      {
                        name: "categories",
                        operator: "NOT_IN",
                        value: categoryIds.join(";"),
                      },
                    ]
                  : []),
                ...skus.map((sku: string) => ({
                  name: "sku",
                  operator: "NOT_EQ",
                  value: sku,
                })),
              ],
            },
          });
        }
        return baseQuery({
          url: API.getProducts.endpoint(),
          method: "POST",
          body,
        });
      },
    }),
    getProductListAttributes: builder.query<
      IProductListAttributesResponse,
      IPaginationGridPayload
    >({
      query: (body) => ({
        url: API.getProductListAttributes.endpoint(),
        method: "POST",
        body,
      }),
      transformResponse: (response: IProductListAttributesResponse) => ({
        ...response,
        result: response.result?.map((attr) => ({
          ...attr,
          name: attr.label,
        })),
      }),
    }),
    getProductListAttributesType: builder.query<
      IProductListAttributesTypeResponse,
      void
    >({
      query: () => API.getProductListAttributesType.endpoint(),
    }),
    getProductProjection: builder.query<IProductProjectionResponse[], void>({
      query: () => API.getProductProjection.endpoint(),
    }),
    getProductListPools: builder.query<
      IProductListPoolsResponse,
      IPaginationGridPayload
    >({
      query: (body) => ({
        url: API.getProductListPools.endpoint(),
        method: "POST",
        body,
      }),
    }),
    getProductListCategoryTree: builder.query<
      IProductListCategoryTreeResponse,
      void
    >({
      query: () => API.getProductListCategoryTree.endpoint(),
      transformResponse: (response: {
        children: IProductListCategoryTreeResponse;
      }) =>
        response?.children?.map(
          function mapper(res): Partial<TCategoryResponse> {
            return {
              id: res?.code,
              name: res?.label,
              children: res?.children?.map(mapper) as TCategory[],
            };
          }
        ),
    }),
    getDynamicAssociationProducts: builder.query<
      { data: ISearchResultsTransformResponse },
      { currentSku: string; attributes: (keyof ISrpTransformResponseV2)[] }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async ({ currentSku, attributes }, { dispatch }) => {
        try {
          const currentProductData = await dispatch(
            searchResults.getPostProducts.initiate({
              skus: [currentSku],
              projection: ["lowestCategoryLevel"].concat(attributes),
            })
          );

          const currentProductAttributes = attributes
            .map((attributeName) => {
              let value = currentProductData.data?.products?.[0][attributeName];
              if (!value) return;

              if (Array.isArray(value)) {
                value = value.map((e) => e.label ?? value).join(";");
              }
              if (typeof value === "object") {
                value = value.label ?? value.value;
              }
              return {
                attributeName,
                value,
              };
            })
            .filter(Boolean) as {
            attributeName: string;
            value: string;
          }[];

          let attributesQuery = currentProductAttributes
            .map((arg) => `filter=${arg.attributeName}:${arg.value}`)
            .concat(`notFilter=sku:${currentSku}`)
            .join("&");

          if (attributes.includes("PortalCategory")) {
            const category =
              currentProductData.data?.products[0].lowestCategoryLevel;

            const categoryLevel = category
              ? Math.floor(category.length / 2) - 1
              : "";

            const levelWithPrefix = categoryLevel
              ? categoryLevel < 10
                ? `0${categoryLevel}`
                : categoryLevel
              : "";

            attributesQuery === ""
              ? (attributesQuery = attributesQuery.concat(
                  `filter=cat${levelWithPrefix}:${category}`
                ))
              : (attributesQuery = attributesQuery.concat(
                  `&filter=cat${levelWithPrefix}:${category}`
                ));
          }

          const associatedSkus = await dispatch(
            searchResults.getSearchResults.initiate({
              query: attributesQuery,
            })
          );

          return attributesQuery === ""
            ? { data: { ...associatedSkus, data: { products: [] } } }
            : { data: associatedSkus };
        } catch (error) {
          return { error };
        }
      },
    }),
    getProductsForSlider: builder.query<
      {
        wishListData?: any;
        lastSeenData?: any;
        dynamicAssociationData?: any;
        productsData?: any;
      },
      {
        contentSettings: IBoxProductSliderData["contentSettings"];
        productSku: string | null;
      }
    >({
      async queryFn(
        { contentSettings, productSku },
        { dispatch },
        _1,
        baseQuery
      ) {
        const skipLastSeen =
          contentSettings.dynamicProductListOption !==
          dynamicProductListOption.LAST_SEEN;
        const skipWishList =
          contentSettings.dynamicProductListOption !==
          dynamicProductListOption.WISH_LIST;
        const skipDynamicAssociation =
          contentSettings.dynamicProductListOption !==
          dynamicProductListOption.DYNAMIC_ASSOCIATION;
        const skipCompare =
          contentSettings.dynamicProductListOption !==
          dynamicProductListOption.COMPARE;

        const isCompareButtonOptionSelected =
          (contentSettings.product.button.show &&
            contentSettings.product.button.type ===
              ProductButtonActions.COMPARISON) ||
          (contentSettings.product.secondaryButton.show &&
            contentSettings.product.secondaryButton.type ===
              ProductButtonActions.COMPARISON) ||
          (contentSettings.product.customActionAttributes.customButton &&
            contentSettings.product.customActionAttributes.customButton.type ===
              ProductButtonActions.COMPARISON);

        const isProductSiteWithAssociation =
          contentSettings.selectedAssociation && productSku;

        const compareResponse = !skipCompare
          ? await baseQuery(API.getProductCompare.endpoint())
          : undefined;

        const lastSeenResponse = !skipLastSeen
          ? await baseQuery(API.getLastSeen.endpoint())
          : undefined;

        const wishListResponse = !skipWishList
          ? await baseQuery(API.getWishList.endpoint())
          : undefined;

        const dynamicAssociationResponse = !skipDynamicAssociation
          ? await baseQuery({
              url: API.getSearchProducts.endpoint(),
              method: "POST",
              body: {
                currentSku: productSku ?? "",
                attributes: contentSettings.attributes,
              },
            })
          : undefined;

        const associationProductsResponse = isProductSiteWithAssociation
          ? await baseQuery({
              url: API.getSearchProducts.endpoint(),
              method: "POST",
              body: {
                associations: [contentSettings.selectedAssociation],
                projection: [],
                skus: [productSku ?? ""],
              },
            })
          : undefined;

        const currentProductsSkus =
          getProductsDataObject(lastSeenResponse?.data)?.products ??
          getProductsDataObject(wishListResponse?.data)?.products ??
          getDynamicAssociationSkus(dynamicAssociationResponse?.data?.data) ??
          compareResponse?.data.skus ??
          associationProductsResponse?.data?.products?.[0]?.associations?.[
            contentSettings.selectedAssociation ?? ""
          ] ??
          [];

        const { query, sort } = getSearchRequestConfig(
          contentSettings,
          currentProductsSkus
        );

        const searchResponse = await dispatch(
          searchResults.getSearchWithSrpResultsV2.initiate(
            {
              withCompare: isCompareButtonOptionSelected ?? false,
              searchQueries: `${query}${
                sort
                  ? `&sort=${getSortQueryBySortType(
                      sort,
                      contentSettings.sliderSettings.sortingAttributeCode,
                      contentSettings.sliderSettings.sortingAttributeVariant
                    )}`
                  : ""
              }`,
              projection: srpProjectionMapper([
                "rating",
                "tax",
                "axis",
                "sku",
                "url",
                "name",
                "concession",
                "wishlist",
                "coverPhoto",
                "hoverPhoto",
                "badges",
                "promotionPrice",
                "basePrice",
                "deliveryTime",
                "variants",
                "netPrice",
                "conceptId",
                "mainUnitOfMeasurement",
                "alternativeUnitOfMeasures",
                "mainUnitOfMeasurementLabel",
                ...Object.keys(contentSettings.product.custom ?? {}),
              ]),
            },
            { subscribe: false }
          )
        );

        return {
          data: {
            wishListData: wishListResponse?.data ?? null,
            lastSeenData: lastSeenResponse?.data ?? null,
            dynamicAssociationData: dynamicAssociationResponse?.data ?? null,
            productsData: searchResponse?.data ?? null,
          },
        };
      },
      providesTags: ["PRODUCT_SLIDER_PRODUCTS", "CONTRACTORS"],
    }),
  }),
});

export const {
  useGetAttributesQuery,
  useLazyGetProductListAttributesQuery,
  useGetProductListAttributesQuery,
  useGetProductListAttributesTypeQuery,
  useLazyGetProductListPoolsQuery,
  useGetProductListPoolsQuery,
  useGetProductListCategoryTreeQuery,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetDynamicAssociationProductsQuery,
  useGetProductProjectionQuery,
  useGetProductsForSliderQuery,
} = productList;

export const {
  getProductListAttributes,
  getProductListAttributesType,
  getProductListPools,
  getProductListCategoryTree,
  getProducts,
  getDynamicAssociationProducts,
  getProductsForSlider,
} = productList.endpoints;
