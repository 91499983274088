import React from "react";
import { nanoid } from "nanoid";
import { SliderPaginationOptions } from "../../../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import { getElementClassName } from "./PaginationGrid.methods";
import { StyledPaginationButton } from "./PaginationGrid.styled";
import { IPaginationGridProps } from "./PaginationGrid.types";

const PaginationGrid: React.FC<IPaginationGridProps> = ({
  currentPage,
  pageCount,
  paginationStyle,
  setCurrentPage,
}) => {
  let elementsCount = Math.min(pageCount, 5);
  let startElementIndex = Math.max(
    currentPage - Math.floor(elementsCount / 2),
    0
  );
  if (pageCount === 3) startElementIndex = 0;
  else if (pageCount > 3 && currentPage === 0) {
    elementsCount = 3;
  } else if (pageCount > 3 && currentPage === 1) {
    elementsCount = 4;
  }

  const paginationElements = new Array(elementsCount)
    .fill(null)
    .map((_, index) => {
      const elementIndex = startElementIndex + index;
      const isInRange = elementIndex >= 0 && elementIndex < pageCount;
      return (
        <StyledPaginationButton
          key={elementIndex + nanoid()}
          style={{
            display: isInRange ? "inline-block" : "none",
          }}
          paginationStyle={paginationStyle}
        >
          <button
            data-testid={`elem-${getElementClassName(
              elementIndex,
              currentPage
            )}`}
            className={getElementClassName(elementIndex, currentPage)}
            onClick={() => setCurrentPage(elementIndex)}
            disabled={elementIndex === currentPage}
          >
            {paginationStyle === SliderPaginationOptions.DOTS
              ? "●"
              : elementIndex + 1}
          </button>
        </StyledPaginationButton>
      );
    });

  return <>{paginationElements}</>;
};

export default PaginationGrid;
