import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss from "../../settingsPatterns/composeCss";

export const StyledProductSlider = styled.div<{
  alignment: AlignmentHorizontalOption;
  padding: ISpacing;
}>(({ alignment, padding }) => {
  return `
  display: flex;
  flex-direction: column;

  .product-slider {
    &__heading {
      display: flex;
      justify-content: ${alignment.toLowerCase()};
      padding: ${composeCss.padding(padding)};
      word-break: break-word;
    }
    }
`;
});
